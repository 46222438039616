import * as React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Button from "react-bootstrap/Button";
import {
  inputFormContainer,
  clickToEnlargeText,
  walnutPrimaryButton,
  walnutSecondaryButton,
} from "../components/layout.module.css";
import Layout from "../components/layout";
import { PageProps, navigate } from "gatsby";
import { IIFormInput } from "./disclaimer";
import { VerifiedProductItem } from "./index";
import { IIIFormInput } from "./input-form";
import Images from "react-bootstrap/Image";
import "react-photo-view/dist/react-photo-view.css";
import axios from "axios";
import Swal from "sweetalert2";
import {CircleSpinnerOverlay} from "react-spinner-overlay";

interface LocationState {
  productState: VerifiedProductItem;
  termState: IIFormInput;
  purchaseState: IIIFormInput;
  receiptImageFile: File;
}
const isBrowser = typeof window !== "undefined";

const imageUploadForm = ({ location }: PageProps) => {
  var stateData = location.state as LocationState;
  const [loading, setLoading] = React.useState<boolean>(false)
  
  if (stateData) {
    const imageObjectURL = URL.createObjectURL(stateData.receiptImageFile);
    const [imageState, setImageState] = React.useState(imageObjectURL);

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      const filesUploaded = event.currentTarget.files;
      if (filesUploaded && filesUploaded.length > 0) {
        stateData.receiptImageFile = filesUploaded[0];
        const imageURL = URL.createObjectURL(filesUploaded[0]);
        setImageState(imageURL);
      }
    };

    const handleClick = (event: React.FormEvent<HTMLButtonElement>) => {
      hiddenFileInput.current?.click();
    };

    const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
      console.log(stateData);
      let fd = new FormData();
      fd.append("receiptImageFile", stateData.receiptImageFile);
      fd.append("productState", JSON.stringify(stateData.productState));
      fd.append("purchaseState", JSON.stringify(stateData.purchaseState));
      setLoading(true)
      axios
        .post(`${process.env.API_URL}/warranty/submit`, fd, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(function (response) {
          setLoading(false)
          navigate("/complete/", {
            state: stateData.productState,
            replace: true
          });
        })
        .catch(function (error) {
          setLoading(false)
          if (error.response) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data,
              confirmButtonColor: "#01B5BB",
            });
          } else if (error.request) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
              confirmButtonColor: "#01B5BB",
            });
            console.log(error.request);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
              confirmButtonColor: "#01B5BB",
            });
            console.log("Error", error.message);
          }
        });
    };

    return (
      <>
      <CircleSpinnerOverlay loading={loading} message="Loading..." color="#01B5BB"/>
      <Layout>        
        <div className={inputFormContainer} style={{ textAlign: "center" }}>
          <PhotoProvider>
            <PhotoView src={imageState}>
              <Images
                className="border border-dark"
                alt="preview"
                src={imageState}
                width="300px"
                height="auto"
              />
            </PhotoView>
          </PhotoProvider>

          <p className={`mt-2 ${clickToEnlargeText}`}>CLICK IMAGE TO ENLARGE</p>
          <p className="mt-3">
            Double check if the receipt can be <strong>clearly seen</strong>.
            Blurred photo may invalidate the receipt and affect warranty claim.
          </p>
          <div className="d-grid mt-5">
            <Button className={walnutSecondaryButton} onClick={handleClick}>
              RETAKE PHOTO
            </Button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              accept="image/png, image/jpeg, image/jpg"
              // capture="environment"
              size={5000000}
              style={{ display: "none" }}
            ></input>
          </div>
          <div className="d-grid mt-3">
            <Button className={walnutPrimaryButton} onClick={handleSubmit}>
              SUBMIT
            </Button>
          </div>
        </div>
      </Layout>
      </>
    );
  } else {
    if (isBrowser) {
      navigate("/", { replace: true });
    }
    return <Layout></Layout>;
  }
};

export default imageUploadForm;
